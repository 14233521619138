<template>
  <div class="card-redemption">
    <el-card>
      <h2 style="margin-left: 15px">卡密兑换</h2>
      <el-form :model="form" :rules="rules" ref="formRef">
        <el-form-item prop="cardNo" label="兑换码">
          <el-input v-model="form.cardNo" placeholder="请输入卡密兑换码" clearable></el-input>
        </el-form-item>
        <el-form-item prop="phone" label="手机号">
          <el-input v-model="form.phone" placeholder="请输入手机号" clearable></el-input>
        </el-form-item>
        <el-button type="primary" @click="submitForm" class="image-button">兑换</el-button>
      </el-form>
      <div v-if="resultMessage" class="result-message">
        {{ resultMessage }}
      </div>
    </el-card>
  </div>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from 'vue';
import {ElForm, ElFormItem, ElInput, ElMessage} from 'element-plus';

const formRef = ref(null);
const form = ref({
  cardNo: '',
  phone: ''
});
const resultMessage = ref('');
const {proxy} = getCurrentInstance();
const rules = ref({
  cardNo: [
    {required: true, message: '请输入卡密', trigger: 'blur'}
  ],
  phone: [
    {required: true, message: '请输入手机号', trigger: 'blur'},
    {validator: validatephone, trigger: 'blur'}
  ]
});

// 校验手机号
function validatephone(rule, value, callback) {
  if (!value) {
    return callback(new Error('请输入手机号'));
  }
  const reg = /^1[3-9]\d{9}$/;
  if (reg.test(value)) {
    callback();
  } else {
    callback(new Error('手机号格式不正确'));
  }
}

// 提交表单
const submitForm = () => {
  formRef.value.validate(async valid => {
    if (valid) {
      try {
        if (!form.value.cardNo || !form.value.phone) {
          throw new Error('请输入卡密和手机号');
        }

        // 假设有一个 API 接口用于兑换卡密
        const response = await proxy.$api.post('https://aki.mitingkeji.com/user/admin/cardSecret/cardSecretCash', {
          cardNo: form.value.cardNo,
          phone: form.value.phone,
        });

        if (response.data.code === 0) {
          resultMessage.value = response.data.data;
          ElMessage.success("操作完成");
        } else {
          resultMessage.value = '兑换失败: ' + response.data.msg;
          ElMessage.error(response.data.msg);
        }
      } catch (error) {
        resultMessage.value = '兑换失败: ' + error.msg;
        ElMessage.error(error.msg);
      }
    } else {
      console.log('error submit!!');
      return false;
    }
  });
};

onMounted(() => {
  // 初始化表单验证规则
  formRef.value.resetFields();
});
</script>

<style scoped>
.card-redemption {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("../assets/image/card/bg1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.el-card {
  width: 400px;
  padding: 20px;
  background-image: url("../assets/image/card/bg2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.result-message {
  margin-top: 20px;
  color: #f56c6c;
}

.image-button {
  width: 100px;
  height: 40px;
  font-size: 20px;
  margin-left: 33%;
  background-size: cover; /* 或者使用 contain, 依据你的需求 */
  background-repeat: no-repeat;
  background-position: center center;
  border: none; /* 移除默认边框 */
  box-shadow: none; /* 移除默认阴影效果 */
  padding: 0; /* 移除默认内边距 */
}
</style>