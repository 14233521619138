import {createApp} from 'vue'
import App from './App.vue'
import router from './router/index'
import './styles/common.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import axios from 'axios'

const app = createApp(App)

// 使用 Element Plus
app.use(ElementPlus)

// 将 axios 添加到全局上下文中
app.config.globalProperties.$api = axios;
app.use(axios)
// 使用路由
app.use(router)

app.mount('#app')