<template>
  <div class="index">
    <!-- 首页 -->
    <div class="page-bg" v-if="show == 'index'">
      <img src="../assets/image/bg1.png" alt="" class="con-bg-img"/>

      <!-- 下载app按钮 -->
      <img src="../assets/image/download-btn.png" alt="" class="download-btn" @click="goDow()"/>
    </div>
    <!-- 关于我们 -->
    <div class="page-bg" v-if="show == 'about'">
      <img src="../assets/image/bg2.png" alt="" class="con-bg-img"/>
    </div>

    <!-- 关于我们 -->
    <div class="page-bg" v-if="show == 'gongsi'">
      <img src="../assets/image/bg3.png" alt="" class="con-bg-img"/>
      <div class="text-overlay">
        <p style="font-size: 36px">公司简介</p>
        <div style="margin-top: 30px">
          厦门米听科技有限公司成立于2023年，注册资本1000万元，是一家充满活力与创新精神的软件和信息技术服务公司。
          我们秉承“发展企业、贡献社会”的核心价值观。我们的团队由一群经验丰富、专业精湛的年轻人组成。
          公司的主要经营范围是技术服务、软件开发、增值电信业务、网络文化经营、互联网信息服务、网络游戏服务等。
          <p>公司目前开发了一款社交互动软件，致力于成为现代年轻人的社交聚集地，赢得大家的认可。</p>
        </div>

      </div>
    </div>
    <!-- 导航栏 -->
    <div class="nav">
      <div class="nav-content">
        <div class="nav-logo" @click="showIndex">
          <img src="../assets/image/logo.png" alt/>
        </div>
        <div class="nav-tag">
          <div>
            <p @click="showIndex">首页</p>
            <div v-if="show == 'index'" class="line"></div>
          </div>
          <div>
            <p @click="showAbout">关于我们</p>
            <div v-if="show == 'about'" class="line"></div>
          </div>
          <div>
            <p @click="showGongsi">公司简介</p>
            <div v-if="show == 'gongsi'" class="line"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <div class="footer-content">
        <div class="footer-content-top">
          <p @click="goPage('/userAgreement')">用户协议</p>
          <div class="line"></div>
          <p @click="goPage('/PrivacyAgreement')">隐私协议</p>
        </div>
        <div class="footer-content-text">
          <p>2024©All Rights Reserved.厦门米听科技有限公司 版权所有</p>
          <p>公司地址：厦门市思明区塔埔东路167号1103室之一</p>
          <p><span>联系电话：15860727176</span>| <span>邮箱: 455295023@qq.com</span></p>
          <p><span>网络文化经营许可证：闽网文（2024）1709-</span> | <span>增值电信业务经营许可证：闽B2-20240121</span></p>
          <p class="pointer" @click="goExternalLink">闽ICP备2023025619号-2</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "indexPage",
  data() {
    return {
      show: "index",
    };
  },

  methods: {
    // 首页
    showIndex() {
      this.show = "index";
    },
    // 关于我们
    showAbout() {
      this.show = "about";
    },
    // 公司
    showGongsi() {
      this.show = "gongsi";
    },
    // 跳转页面
    goPage(url) {
      this.$router.push(url);
    },
    // 链接工信部
    goExternalLink() {
      window, (location.href = "https://beian.miit.gov.cn/");
    },
    // 链接工信部
    goDow() {
      window, (location.href = "https://oss.mitingkeji.com/app/miting.apk");
    },
  },
};
</script>

<style scoped lang="scss">
.index {
  width: 100%;
  height: 100vh;
  min-width: 1920px;
  min-height: 1080px;
  background-color: #0d0e26;
  display: flex;
  justify-content: center;
  align-items: center;

  .page-bg {
    width: 1920px;
    height: 1080px;
    position: relative;

    .con-bg-img {
      display: block;
      width: 100%;
      height: 100%;
    }

    .download-btn {
      width: 216px;
      height: 60px;
      position: absolute;
      left: 600px;
      top: 580px;
      cursor: pointer;
    }
  }
}

.nav {
  width: 100%;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  justify-content: center;

  .nav-content {
    width: 1100px;
    display: flex;
    justify-content: space-between;

    .nav-logo {
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        width: 130px;
        height: 40px;
      }
    }

    .nav-tag {
      width: 160px;
      height: 100%;
      line-height: 60px;
      display: flex;

      div {
        width: 80px;
        height: 100%;
        text-align: center;
        cursor: pointer;
        position: relative;

        p {
          width: 80px;
          height: 58px;
          color: #fff;
          font-size: 14px;
        }

        .line {
          width: 12px;
          height: 2px;
          background-color: #fff;
          position: absolute;
          left: 50%;
          bottom: 14px;
          margin-left: -6px;
        }
      }
    }
  }
}

.footer {
  width: 100%;
  height: 170px;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;

  .footer-content {
    width: 1100px;
    text-align: center;
    padding-top: 30px;
    color: #fff;

    .footer-content-top {
      height: 20px;
      line-height: 20px;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      font-size: 14px;

      p {
        cursor: pointer;
      }

      .line {
        width: 2px;
        height: 16px;
        background-color: #fff;
        margin: 2px 10px;
      }
    }

    .footer-content-text {
      font-size: 12px;

      p {
        margin: 3px 0;
      }

      .pointer {
        cursor: pointer;
      }
    }
  }
}

.text-overlay {
  position: absolute;
  top: 30%;
  left: 45%;
  transform: translate(-50%, -50%);
  color: white; /* 或者选择与背景图片对比度高的颜色 */
  text-align: center;
  font-size: 24px; /* 根据需要调整字体大小 */
}
</style>
